<template>
  <div class="main-container">
    <v-toolbar
        flat
        tile
        class="box"
        style="background-color:transparent"
    >
      <v-btn icon @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title class="pl-0">
        ข้อมูลการเติมน้ำมันตามรถ
      </v-toolbar-title>
      <v-spacer/>
    </v-toolbar>
    <div class="mx-4 pb-4 box box-2" style="min-height: 100%;">
      <v-card height="100%" class="px-2 pt-2" style="overflow: auto">
        <v-data-table @click:row="goTrackByCar" :headers="header" :items="vehicles"
                      :header-props="{ sortIcon: null }">
        </v-data-table>
      </v-card>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: "OilTrackerByCarList",
  data() {
    return {
      vehicles: [],
      header: [
        {
          text: 'ชื่อรถ',
          value: 'name'
        },
        {
          text: 'ทะเบียนรถ',
          value: 'licence',
          align: 'center'
        },
        {
          text: 'ประเภท',
          value: 'type.name',
          align: 'center'
        },
      ]
    }
  },
  created() {
    this.getVehicle()
  },
  methods: {
    getVehicle() {
      axios.get('/api/stocks/vehicle/')
          .then(res => {
            this.vehicles = res.data.data
          })
          .catch(e => {
            this.$store.state.snackbar = {
              text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : ' + e.response.status + ' ' + e.response.statusText,
              visible: true,
              color: 'error'
            }
          })
    },
    goTrackByCar(row) {
      console.log(row)
      this.$router.push({path: `/oil-tracking/${row.id}`})
    }
  }
}
</script>

<style scoped>

</style>
